export const environment = {
  production: false,
  currentEnv: 'qat',
  protocol: 'https://',
  oauthGrantType: 'client_credentials',
  oauthResource: '',
  apsOAuthClientId: 'dace94c0-1a43-4160-9e46-7fd4358436d1',
  clientId: '370292aa-c4b6-4734-9185-518ab2f0523c',
  apsOAuthClientSecret: 'M2eF7pH4rF4fQ2oN3eA2qX5gC0hT0fV5uF6jE4gS2iJ0xC8gX8',
  apsOAuthScope: 'pdp',
  apsOAuthProviderUrl: 'https://apigtwqa.ford.com/oneford/api/pdpoauthproviderapi/oauth2/token',
  applicationIds: [
    'FENIX',
    'FLARE',
    'FORCE',
    'ORFIN',
    'VADR',
    'FenixODOM'
  ],
  fordUserAuthUrl: 'https://apigtwqa.ford.com/oneford/api/aps-mdp/permittedResources',
  homePageUrl: 'https://qat.ota.ford.com/home',
  apsRules: [
    {key: 'fenixWebsite', value: {type: 'read', resource: 'FENIX:FENIXWebsite'}},
    {key: 'fenixManageGroupsAndVehicles', value: {type: 'read', resource: 'FENIX:MANAGEGROUPSANDVEHICLESETS'}},
    {key: 'fenixMassDeployment', value: {type: 'read', resource: 'FENIX:MASSDEPLOYMENT'}},
    {key: 'fenixVinUserRegistration', value: {type: 'read', resource: 'FENIX:VINUSERREGISTRATION'}},
    {key: 'fenixGovVinlist', value: {type: 'execute', resource: 'FENIX:GovVinlist'}},
    {key: 'fenixVEVvinlists', value: {type: 'execute', resource: 'FENIX:VEVvinlists'}},
    {key: 'flareWebsite', value: {type: 'read', resource: 'FLARE:FLAREWebsite'}},
    {key: 'flarePackageDashboard', value: {type: 'execute', resource: 'FLARE:PackageDashboard'}},
    {key: 'flareVinLookup', value: {type: 'execute', resource: 'FLARE:HistoricalVINDiscoverySearch'}},
    {key: 'flareVILUtilities', value: {type: 'execute', resource: 'FLARE:VILUtilities'}},
    {key: 'flareQueryManagement', value: {type: 'execute', resource: 'FLARE:QueryManagement'}},
    {key: 'flareManageAutomatedConfigurations', value: {type: 'execute', resource: 'FLARE:ManageAutomatedConfigurations'}},
    {key: 'forceWebsite', value: {type: 'read', resource: 'FORCE:FORCEWebsite'}},
    {key: 'orfinManageVisPage', value: {type: 'read', resource: 'ORFIN:VIS:ManageVISPage'}},
    {key: 'orfinCreateCustomVISPage', value: {type: 'read', resource: 'ORFIN:VIS:CreateCustomVISPage'}},
    {key: 'orfinProductionPackageQueue', value: {type: 'read', resource: 'ORFIN:ProductionPackageQueue'}},
    {key: 'orfinProWebsite', value: {type: 'read', resource: 'ORFIN:ORFINPROWebsite'}},
    {key: 'orfinReasonForChangeUpLoad', value: {type: 'execute', resource: 'ORFIN:ReasonForChangeUpload'}},
    {key: 'orfinUploadDefaultReleaseNotes', value: {type: 'update', resource: 'ORFIN:UploadDefaultReleaseNotes'}},
    {key: 'vadrWebsite', value: {type: 'execute', resource: 'VADR:VADRWebSite'}},
    {key: 'vadrWebsite', value: {type: 'read', resource: 'VADR:VADRWebSite'}},
    {key: 'fenixODOMWebSite', value: {type: 'read', resource: 'FenixODOM:FenixODOMWebSite'}}
  ],
  oauth: {
    ssoServiceUrl: 'https://login.microsoftonline.com/c990bb7a-51f4-439b-bd36-9c07fb1041c0',
    ssoClientId: '370292aa-c4b6-4734-9185-518ab2f0523c',
    ssoRedirectUri: 'https://qat.ota.ford.com/oauth-callback',
    ssoResponseType: 'id_token',
    ssoResourceId: '',
    ssoEnabled: true
  },
  apsPepUrl: 'https://api-qat.vehicle.ford.com/api/vadrGateway/ota-aps-pep',
  idTokenKey: 'encodedIdToken',
  tokenExpKey: 'tokenExp',
  apsRulesKey: 'apsRules',
  baseUrls: [
    {key: 'fire', value: 'https://qat.ota.ford.com/fire/'},
    {key: 'fireSplunk', value: 'https://wwwqa.splunk.ford.com/'},
    {key: 'flare', value: 'https://qat.ota.ford.com/flare/'},
    {key: 'force', value: 'https://qat.ota.ford.com/force/'},
    {key: 'orfin', value: 'https://qat.ota.ford.com/ota/'},
    {key: 'orfinpro', value: 'https://qat.ota.ford.com/orfinpro/'},
    {key: 'vadr', value: 'https://qat.ota.ford.com/ota/'},
    {key: 'vadrConformance', value: 'https://qat.oct.ford.com/'},
    {key: 'odom', value: 'https://qat.ota.ford.com/odom/'}
  ],
  profileMenuOptions: {
    displayLoggedInState: true,
    displayUserName: true
  },
  hotLinks: [
    {
      header: [
        'SW Management (VADR)'
      ],
      links: [
        {
          label: 'Upload File and Metadata',
          url: 'https://qat.ota.ford.com/ota/vadr/fileUpload/',
          security: {type: 'execute', resource: 'VADR:VADRWebSite'}
        },
        {
          label: 'Create Application',
          url: 'https://qat.ota.ford.com/ota/vadr/applications/create/step-1-select-domain',
          security: {type: 'execute', resource: 'VADR:VADRWebSite'}
        },
        {
          label: 'Create Domain Instance',
          url: 'https://qat.ota.ford.com/ota/vadr/domainInstance/create/selectDomain',
          security: {type: 'execute', resource: 'VADR:VADRWebSite'}
        },
        {
          label: 'Manage Application',
          url: 'https://qat.ota.ford.com/ota/vadr/applications/manage',
          security: {type: 'execute', resource: 'VADR:VADRWebSite'}
        },
        {
          label: 'Manage Update Construct',
          url: 'https://qat.ota.ford.com/orfinpro/updateconstruct/manage',
          security: {type: 'execute', resource: 'VADR:VADRWebSite'}
        }
      ],
      accessRequest: {
        label: 'Request Access',
        url: 'https://wwwedu.accessmgmt.dealerconnection.com/CspsWeb/cspsHomeBegin.do'
      },
      viewDashboard: {
        label: 'View Dashboard',
        url: ''
      }
    },
    {
      header: [
        'Deployment (FIRE)'
      ],
      links: [
        {
          label: 'Create Rollout w/Package',
          url: 'https://qat.ota.ford.com/fire/rollout/create-rollout-with-packages',
          security: {type: 'read', resource: 'FENIX:FENIXWebsite'}
        },
        {
          label: 'Create Rollout w/Software Set',
          url: 'https://qat.ota.ford.com/fire/rollout/create-rollout',
          security: {type: 'read', resource: 'FENIX:FENIXWebsite'}
        },
        {
          label: 'Manage Rollout',
          url: 'https://qat.ota.ford.com/fire/rollout/manage-rollouts-v2',
          security: {type: 'read', resource: 'FENIX:FENIXWebsite'}
        },
        {
          label: 'VIN Lookup',
          url: 'https://qat.ota.ford.com/fire/utility/vin-lookup-v2',
          security: {type: 'read', resource: 'FENIX:FENIXWebsite'}
        },
        {
          label: 'Create Groups',
          url: 'https://qat.ota.ford.com/fire/create-groups',
          security: {type: 'read', resource: 'FENIX:FENIXWebsite'}
        },
        {
          label: 'Manage Groups and Vehicle Sets',
          url: 'https://qat.ota.ford.com/fire/manage-groups-and-vehicleset-v2',
          security: {type: 'read', resource: 'FENIX:FENIXWebsite'}
        },
        {
          label: 'Vehicle Details Upload',
          url: 'https://qat.ota.ford.com/fire/vehicle-details-upload',
          security: {type: 'read', resource: 'FENIX:FENIXWebsite'}
        }
      ],
      accessRequest: {
        label: 'Request Access',
        url: 'https://www.accessmgmt.ford.com/CspsWeb/cspsHomeBegin.do'
      },
      viewDashboard: {
        label: 'View Dashboard',
        url: 'https://splunk.shc.ford.com/en-US/app/fenix_fire/fenix_fire_prod_metrics'
      }
    },
    {
      header: [
        'Governance (ORFIN‑PRO)'
      ],
      links: [
        {
          label: 'Manage Software Packages',
          url: 'https://qat.ota.ford.com/orfinpro/package/softwaremanage',
          security: {type: 'read', resource: 'ORFIN:ProductionPackageQueue'}
        },
        {
          label: 'Manage VIS Packages',
          url: 'https://qat.ota.ford.com/orfinpro/package/vismanage',
          security: {type: 'read', resource: 'ORFIN:ProductionPackageQueue'}
        },
        {
          label: 'Manage Super Packages',
          url: 'https://qat.ota.ford.com/orfinpro/superpackage/manage',
          security: {type: 'read', resource: 'ORFIN:MGSMSuperPackageCreationAndManagement'}
        },
        {
          label: 'Manage Update Construct - Workflow',
          url: 'https://qat.ota.ford.com/orfinpro/updateconstruct/manage-workflow',
          security: {type: 'read', resource: 'ORFIN:UpdateConstructWorkflowManagement'}
        },
        {
          label: 'Manage Release Notes',
          url: 'https://qat.ota.ford.com/orfinpro/releasenote/manage',
          security: {type: 'read', resource: 'ORFIN:ReleaseNoteDataManagement'}
        },
        {
          label: 'Upload Default Content',
          url: 'https://qat.ota.ford.com/orfinpro/defaultcontent/upload',
          security: {type: 'read', resource: 'ORFIN:ProductionPackageQueue'}
        },
        {
          label: 'Excel To Json Utility (RN and AUC)',
          url: 'https://qat.ota.ford.com/orfinpro/utility/exceltojson',
          security: {type: 'read', resource: 'ORFIN:ORFINPROWebsite'}
        }
      ],
      accessRequest: {
        label: 'Request Access',
        url: 'https://wwwedu.accessmgmt.dealerconnection.com/CspsWeb/cspsHomeBegin.do'
      },
      viewDashboard: {
        label: 'View Dashboard',
        url: ''
      }
    },
    {
      header: [
        'Vehicle Interrogator',
        'Schema Manager'
      ],
      links: [
        {
          label: 'Create Custom VIS',
          url: 'https://qat.ota.ford.com/orfinpro/vis/create',
          security: {type: 'read', resource: 'ORFIN:VIS:ManageVISPage'}
        },
        {
          label: 'Manage VIS',
          url: 'https://qat.ota.ford.com/orfinpro/vis/vis-manage',
          security: {type: 'read', resource: 'ORFIN:VIS:ManageVISPage'}
        }
      ],
      accessRequest: {
        label: 'Request Access',
        url: 'https://wwwedu.accessmgmt.dealerconnection.com/CspsWeb/cspsHomeBegin.do'
      },
      viewDashboard: {
        label: 'View Dashboard',
        url: ''
      }
    },
    {
      header: [
        'Discovery (FLARE)'
      ],
      links: [
        {
          label: 'Package Dashboard',
          url: 'https://qat.ota.ford.com/flare/package-dashboard',
          security: {type: 'execute', resource: 'FLARE:PackageDashboard'}
        },
        {
          label: 'VIL Utilities',
          url: 'https://qat.ota.ford.com/flare/vin-utilities',
          security: {type: 'execute', resource: 'FLARE:HistoricalVINDiscoverySearch'}
        },
        {
          label: 'Query Management Dashboard',
          url: 'https://qat.ota.ford.com/flare/query-management-dashboard',
          security: {type: 'execute', resource: 'FLARE:QueryManagement'}
        },
        {
          label: 'Manage Automated Configurations',
          url: 'https://qat.ota.ford.com/flare/manage-automated-configurations',
          security: {type: 'execute', resource: 'FLARE:ManageAutomatedConfigurations'}
        }
      ],
      accessRequest: {
        label: 'Request Access',
        url: 'https://wwwedu.accessmgmt.dealerconnection.com/CspsWeb/cspsHomeBegin.do'
      },
      viewDashboard: {
        label: 'View Dashboard',
        url: ''
      }
    },
    {
      header: [
        'Direct Configurations (FORCE)'
      ],
      links: [
        {
          label: 'ConfigSet Catalog',
          url: 'https://qat.ota.ford.com/force/direct-configurations/configset',
          security: {type: 'read', resource: 'FORCE:FORCEWebsite'}
        },
        {
          label: 'ConfigSet Parameter Catalog',
          url: 'https://qat.ota.ford.com/force/direct-configurations/configset/parameter-search',
          security: {type: 'read', resource: 'FORCE:FORCEWebsite'}
        },
        {
          label: 'ConfigSet Import',
          url: 'https://qat.ota.ford.com/force/direct-configurations/configset/import',
          security: {type: 'read', resource: 'FORCE:FORCEWebsite'}
        },
        {
          label: 'Initializer Catalog',
          url: 'https://qat.ota.ford.com/force/direct-configurations/initializer',
          security: {type: 'read', resource: 'FORCE:FORCEWebsite'}
        },
        {
          label: 'Initializer Import',
          url: 'https://qat.ota.ford.com/force/direct-configurations/initializer/import',
          security: {type: 'read', resource: 'FORCE:FORCEWebsite'}
        },
        {
          label: 'MDX Catalog',
          url: 'https://qat.ota.ford.com/force/direct-configurations/mdx',
          security: {type: 'read', resource: 'FORCE:FORCEWebsite'}
        },
        {
          label: 'Upload Part 2',
          url: 'https://qat.ota.ford.com/force/direct-configurations/mdx/upload',
          security: {type: 'read', resource: 'FORCE:FORCEWebsite'}
        }
      ],
      accessRequest: {
        label: 'Request Access',
        url: 'https://wwwedu.accessmgmt.dealerconnection.com/CspsWeb/cspsHomeBegin.do'
      },
      viewDashboard: {
        label: 'View Dashboard',
        url: ''
      }
    },
    {
      header: [
        'OTA Deployment Operations Management (ODOM)'
      ],
      links: [
        {
          label: 'OTA Sequence',
          url: 'https://qat.ota.ford.com/odom/sequence',
          security: {type: 'read', resource: 'FenixODOM:FenixODOMWebSite'}
        },
        {
          label: 'Manage Hold',
          url: 'https://qat.ota.ford.com/odom/hold-management',
          security: {type: 'read', resource: 'FenixODOM:FenixODOMWebSite'}
        }
      ],
      accessRequest: {
        label: 'Request Access',
        url: 'https://wwwedu.accessmgmt.dealerconnection.com/CspsWeb/cspsHomeBegin.do'
      },
      viewDashboard: {
        label: 'View Dashboard',
        url: ''
      }
    }
  ],
  videoLinks:[
    {
      title: 'VADR Overview Part 1',
      file: './assets/videos/VADR-Overview-Part1.mp4',
      url:'https://videosat.ford.com/#/videos/c14368bd-4bb3-455b-a449-07cd91c2ffc7'
    },
    {
      title: 'VADR Overview Part 2',
      file: './assets/videos/VADR-Overview-Part2.mp4',
      url:'https://videosat.ford.com/#/videos/639fa31d-7326-4b77-b73b-74dac7ff6710'
    },
    {
      title: 'VADR Overview Part 3',
      file: './assets/videos/VADR-Overview-Part3.mp4',
      url: 'https://videosat.ford.com/#/videos/58f88f77-86c0-454d-b799-4bb5129031f8'
    }
  ]
};
